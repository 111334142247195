import IITGLogoImage from "assets/images/IITG_Logo.webp";
import NasscomFSImage from "assets/images/Nasscom_FS.png";
import {dataScienceWithAiSpecializationSlug} from './courseStaticPage';

// const isMobileView = window.innerWidth <= 768;
export const dataAnalytics = [
  [
    {
      content: "Program Details",
      backgroundColor: "rgb(28, 69, 135)",
      textColor: "rgb(255, 255, 255)",
      isBold: true
    },
    {
      content: "Bootcamp Certificate in Data Analytics",
      backgroundColor: "rgb(28, 69, 135)",
      textColor: "rgb(255, 255, 255)",
      isBold: true
    },
    {
      content: "PG Diploma in Data Analytics",
      backgroundColor: "rgb(28, 69, 135)",
      textColor: "rgb(255, 255, 255)",
      isBold: true
    }
  ],
  [
    {
      content: "Duration",
      backgroundColor: "rgb(28, 69, 135)",
      textColor: "rgb(255, 255, 255)",
      isBold: true
    },
    { content: "6 months" },
    { content: "10 months" }
  ],
  [
    {
      content: "Learning Hours",
      backgroundColor: "rgb(28, 69, 135)",
      textColor: "rgb(255, 255, 255)",
      isBold: true
    },
    { content: "500 hours" },
    { content: "1500 hours" }
  ],
  [
    {
      content: "Program Fee(₹)",
      backgroundColor: "rgb(28, 69, 135)",
      textColor: "rgb(255, 255, 255)",
      isBold: true
    },
    {
      isNestedTable: true,
      nestedTableData: [
        [
          {
            isOnlyImage: true,
            imageFile: NasscomFSImage,
            content: "Future Skill Nasscom",
            backgroundColor: "rgb(256, 256, 256)",
            isBold: true,
            borderBottom: true,
            borderRight: true
          },
          {
            isOnlyImage: true,
            imageFile: IITGLogoImage,
            backgroundColor: "rgb(256, 256, 256)",
            content: "E&ICT, IIT Guwahati",
            isBold: true,
            borderBottom: true,
            borderLeft: true
          }
        ],
        [
          { content: "53,800 + GST", borderTop: true, borderRight: true },
          {
            content: "1,19,000 + GST",
            subtext: "* Save Up to 40%",
            borderTop: true,
            borderLeft: true
          }
        ]
      ]
    },
    { content: "2,75,000 + GST" }
  ]
];

export const dataAnalyticsTableText =
  "Easy monthly payments of ₹10,581/- with No Cost EMI*";

export const businessAnalyticsTable = [
  [
    {
      content: "Program Fee (₹)",
      backgroundColor: "rgb(28, 69, 135)",
      textColor: "rgb(255, 255, 255)",
      isBold: true
    },
    {
      isOnlyImage: true,
      imageFile: NasscomFSImage,
      content: "Bootcamp Certificate in Data Analytics",
      backgroundColor: "rgb(255, 255, 255)",
      textColor: "rgb(255, 255, 255)",
      isBold: true
    },
    {
      isOnlyImage: true,
      imageFile: IITGLogoImage,
      content: "PG Diploma in Data Analytics",
      backgroundColor: "rgb(255, 255, 255)",
      textColor: "rgb(255, 255, 255)",
      isBold: true
    }
  ],
  [
    {
      content: "Blended eLearning",
      subtext: "(With live Doubt Sessions)",
      backgroundColor: "rgb(28, 69, 135)",
      textColor: "rgb(255, 255, 255)",
      isBold: true
    },
    { content: "INR 38,000 + GST" },
    { content: "85,000 + GST", subtext: "*Save up to 40%" }
  ],
  [
    {
      content: "Weekend Classes",
      subtext: "(Offline & Online)",
      backgroundColor: "rgb(28, 69, 135)",
      textColor: "rgb(255, 255, 255)",
      isBold: true
    },
    { content: "INR 48,000 + GST" },
    { content: "1,09,000 + GST", subtext: "*Save up to 40%" }
  ]
];

export const businessAnalyticsTableText =
  "Easy monthly payments of ₹7,473/- with No Cost EMI*";

export const dataScienceTable = [
  [
    {
      content: "Program Fee (₹)",
      backgroundColor: "rgb(28, 69, 135)",
      textColor: "rgb(255, 255, 255)",
      isBold: true
    },
    {
      isOnlyImage: true,
      imageFile: NasscomFSImage,
      content: "Bootcamp Certificate in Data Analytics",
      backgroundColor: "rgb(255, 255, 255)",
      textColor: "rgb(255, 255, 255)",
      isBold: true
    },
    {
      isOnlyImage: true,
      imageFile: IITGLogoImage,
      content: "PG Diploma in Data Analytics",
      backgroundColor: "rgb(255, 255, 255)",
      textColor: "rgb(255, 255, 255)",
      isBold: true
    }
  ],
  [
    {
      content: "Blended eLearning",
      subtext: "(With live Doubt Sessions)",
      backgroundColor: "rgb(28, 69, 135)",
      textColor: "rgb(255, 255, 255)",
      isBold: true
    },
    { content: "INR 42,000 + GST" },
    { content: "85,000 + GST", subtext: "*Save up to 40%" }
  ],
  [
    {
      content: "Weekend Classes",
      subtext: "(Offline & Online)",
      backgroundColor: "rgb(28, 69, 135)",
      textColor: "rgb(255, 255, 255)",
      isBold: true
    },
    { content: "INR 53,800 + GST" },
    { content: "1,09,000 + GST", subtext: "*Save up to 40%" }
  ],
  [
    {
      content: "Weekday Bootcamp",
      subtext: "(Offline & Online)",
      backgroundColor: "rgb(28, 69, 135)",
      textColor: "rgb(255, 255, 255)",
      isBold: true
    },
    { content: "INR 59,200 + GST" },
    { content: "1,19,000 + GST", subtext: "*Save up to 40%" }
  ]
];

export const dataScienceTableText =
  "Easy monthly payments of ₹8,260/- with No Cost EMI*";





  export const dataScienceWithAiSpecializationTable = [
    [
      {
        content: "Program Fee (₹)",
        backgroundColor: "rgb(28, 69, 135)",
        textColor: "rgb(255, 255, 255)",
        isBold: true
      },
      {
        isOnlyImage: true,
        imageFile: NasscomFSImage,
        content: "Bootcamp Certificate in Data Analytics",
        backgroundColor: "rgb(255, 255, 255)",
        textColor: "rgb(255, 255, 255)",
        isBold: true
      },
      {
        isOnlyImage: true,
        imageFile: IITGLogoImage,
        content: "PG Diploma in Data Analytics",
        backgroundColor: "rgb(255, 255, 255)",
        textColor: "rgb(255, 255, 255)",
        isBold: true
      }
    ],
    [
      {
        content: "Blended eLearning",
        subtext: "(With live Doubt Sessions)",
        backgroundColor: "rgb(28, 69, 135)",
        textColor: "rgb(255, 255, 255)",
        isBold: true
      },
      { content: "INR 64,900 + GST" },
      { content: "1,25,000 + GST", subtext: "*Save up to 40%" }
    ],
    [
      {
        content: "Weekend Classes",
        subtext: "(Offline & Online)",
        backgroundColor: "rgb(28, 69, 135)",
        textColor: "rgb(255, 255, 255)",
        isBold: true
      },
      { content: "INR 84,500 + GST" },
      { content: "1,69,000 + GST", subtext: "*Save up to 40%" }
    ]
  ];
  
  export const dataScienceWithAiSpecializationTableText =
    "Easy monthly payments of ₹12,764/- with No Cost EMI*";

    export const dataScienceWithAiSpecializationSubTitle = 'Earn a Dual Certification with AnalytixLabs in Collaboration with'

export const businessAnalyticsPagesListAboveOverView = [
  "/business-analytics-data-science-course",
  "/business-analyst-course-in-delhi",
  "/business-analytics-course-in-bangalore",
  "/data-science-course-in-gurgaon",
  "/data-science-course-training-bangalore"
];
export const TableViewBelowOverViewComponent = [
  "/data-science-specialization-course",
  "/data-science-course-in-delhi",
  "/data-science-course-in-noida"
];
export const commonTextTableFile = `<p><b>Includes Placement Readiness Program (PRP):</b> A 2-month post-certification, industry-focused module designed to enhance technical skills, communication, and problem-solving, featuring interview prep, practice tests, and simulated placement days for real-world experience and readiness.</p>`;

const businessAnalyticsPages = [
  "/business-analytics-data-science-course",
  "/business-analyst-course-in-delhi",
  "/business-analytics-course-in-bangalore"
];
const dataSciencePages = [
  "/data-science-course-in-gurgaon",
  "/data-science-course-training-bangalore",
  "/data-science-specialization-course",
  "/data-science-course-in-delhi",
  "/data-science-course-in-noida"
];
const dataAnalyticsPages = [
  "/pg-in-data-analytics",
  "/data-analyst-training-course-in-delhi",
  "/data-analyst-training-course-in-noida",
  "/data-analyst-training-course-in-gurgaon",
  "/data-analytics-training-course-in-bangalore"
];
const dataScienceWithAiSpecialization = [
  dataScienceWithAiSpecializationSlug.slug,
];

export const selectCommonTableContent = url => {
  if (businessAnalyticsPages.includes(url)) {
    return {
      data: businessAnalyticsTable,
      text: businessAnalyticsTableText
    };
  }
  if (dataSciencePages.includes(url)) {
    return {
      data: dataScienceTable,
      text: dataScienceTableText
    };
  }
  if (dataAnalyticsPages.includes(url)) {
    return {
      data: dataAnalytics,
      text: dataAnalyticsTableText
    };
  }
  if (dataScienceWithAiSpecialization.includes(url)) {
    return {
      data: dataScienceWithAiSpecializationTable,
      text: dataScienceWithAiSpecializationTableText
    };
  }
  return {
    data: [],
    text: ""
  };
};
