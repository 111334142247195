export const pgDiploma = {
  courseID: 9999,
  courseCategoryID: 3,
  subTitle: '(BLENDED CLASSROOM)',
  courseFullName: 'PG in Data Science',
  name: 'Bootcamp Certification',
  slug: 'pg-in-data-science',
  metaTitle:
    'Data Science Certification Course & Placement in Bangalore Delhi India',
  metaDescription:
    'Data science courses by AnalytixLabs - Govt. & Industry Certified. Learn with the Best online Data Science certification courses, syllabus, with placements.',
  metaKeywords:
    'data science course, data science certificate, data science certification, data science online course, data science course in India, data science course in Delhi, data science training in Bangalore, data science course in Noida',
  tags: '4',
  CourseBatches: [
    {
      courseID: 9999,
      learningModeID: 1,
      name: "​Data Visualization & Analytics (BLR) Dec'22",
      seat: 15,
      cityID: 1,
      startDate:'2025-02-27T00:00:00.000Z',
      batchTime: '9:30 AM - 1:30 PM',
      City: {
        cityID: 4,
        name: 'Gurgaon',
        label: 'metro',
        displayOrder: 4,
        isWorking: true,
        createdAt: '2020-08-24T11:56:43.000Z',
        updatedAt: '2020-08-24T11:56:43.000Z',
      },  
    },
    {
      courseID: 9999,
      learningModeID: 1,
      ioo: '',
      name: "​Data Visualization & Analytics (BLR) Dec'22",
      seat: 14,
      cityID: 1,
      startDate: '2025-02-20T00:00:00.000Z',
      batchTime: '9:30 AM - 1:30 PM',
      City: {
        cityID: 1,
        name: 'Bangalore',
        label: 'metro',
        displayOrder: 1,
        isWorking: true,
        createdAt: '2020-08-24T11:56:43.000Z',
        updatedAt: '2020-08-24T11:56:43.000Z',
      },
    },
    {
      courseID: 9999,
      ioo: '',
      learningModeID: 1,
      name: "​Data Visualization & Analytics (NOI) '23",
      seat: 10,
      cityID: 8,
      startDate: '2025-02-13T00:00:00.000Z',
      batchTime: '9:30 AM - 1:30 PM',
      City: {
        cityID: 8,
        name: 'Noida',
        label: 'metro',
        displayOrder: 8,
        isWorking: true,
        createdAt: '2020-08-24T11:56:43.000Z',
        updatedAt: '2020-08-24T11:56:43.000Z',
      },
    },
  ],
  LMSCourse: 'ckepznduu00attqjl6qni9nwp',
  feesRecord: [
    {
      courseID: 9999,
      learningModeID: 3,
    },
    {
      courseID: 9999,
      learningModeID: 1,
    },
    {
      courseID: 9999,
      learningModeID: 2,
    },
  ],
}

export const blkClasses = [
  [`rgb(255, 234, 120)`, `rgb(255, 246, 197)`, `rgb(255, 234, 120)`],
  [`rgb(120, 255, 209)`, `rgb(197, 255, 235)`, `rgb(120, 255, 209)`],
  [`rgb(145, 216, 243)`, `rgb(207, 238, 250)`, `rgb(145, 216, 243)`],
]

export let defaultCardCount = 3

export const pgDeplomaCourse = {
  courseID: 9999,
  name: 'PG Diploma in Data Science',
}

export const PGCPCourse = {
  courseID: 9998,
  name: 'PG Certification in Data Science',
}

export const PGCPACourse = {
  courseID: 9997,
  name: 'PG Certification in Data Analytics',
}

export const PGDACourse = {
  courseID: 9996,
  name: 'PG Diploma in Data Analytics',
}

export const StaticPGCityPageCourse = {
  courseID: 9993,
  name: 'PG Certification in Data Analytics',
}
