import { EMITextALForManagersLeaders } from "./staticCoursePage/AIForManagersLeadersData";
import { EMITextGenerativeAICourse } from "./staticCoursePage/GenerativeAICourse";
import playImage from "assets/images/play-1.png";
import liveImage from "assets/images/svgs/streamingIcon.png";
import socialImage from "assets/images/social-1.png";

const AIEngineeringCourseSlug = {
  slug: "/artificial-intelligence-certification-courses-online"
};

const GenerativeAICourseSlug = {
  slug: "/generative-ai-course",
  courseId: "96" // GenerativeAICourse Course Page courseId add form ALCP
};

const AIForManagersLeadersSlug = {
  slug: "/artificial-intelligence-for-managers-leaders",
  courseId: "95" // AIForManagersLeaders Course Page courseId add form ALCP
};

const DataScienceNoidaCourseSlug = {
  slug: "/data-science-course-in-noida"
};

const dataScienceWithAiSpecializationSlug = {
  slug: "/executive-certification-in-data-science-with-ai-specialization",
  title: "Executive Certification in Data Science with AI Specialization",
  courseID: 9996
};

const AICourseInBangaloreDataSlug = {
  slug: "/artificial-intelligence-course-in-bangalore"
};

const AICourseInDelhiDataSlug = {
  slug: "/artificial-intelligence-course-in-delhi"
};

const StaticPGCityPage = [
  "/data-analyst-training-course-in-noida",
  "/data-analyst-training-course-in-delhi",
  "/data-analytics-training-course-in-bangalore",
  "/data-analyst-training-course-in-gurgaon"
];

const StaticPGCityPageCTAContactUsModal = [
  "/pg-in-data-analytics",
  "/data-analyst-training-course-in-noida",
  "/data-analyst-training-course-in-delhi",
  "/data-analytics-training-course-in-bangalore",
  "/data-analyst-training-course-in-gurgaon"
];

const staticCourseFormDownloadBrochure = [
  dataScienceWithAiSpecializationSlug.slug
];

const staticCoursePageSlugArray = [
  AIEngineeringCourseSlug?.slug,
  DataScienceNoidaCourseSlug?.slug,
  AICourseInBangaloreDataSlug?.slug,
  AICourseInDelhiDataSlug?.slug,
  AIForManagersLeadersSlug?.slug,
  GenerativeAICourseSlug?.slug
];

const withStaticCoursePageSlugArray = [
  AIEngineeringCourseSlug?.slug,
  DataScienceNoidaCourseSlug?.slug,
  dataScienceWithAiSpecializationSlug?.slug,
  AICourseInBangaloreDataSlug?.slug,
  AICourseInDelhiDataSlug?.slug,
  AIForManagersLeadersSlug?.slug,
  GenerativeAICourseSlug?.slug
];

// const onlyStaticCoursePageSlugArray = [ dataScienceWithAiSpecializationSlug?.slug.slice(1)]

const ContactUsModalFormStaticDataShowArray = [
  AICourseInBangaloreDataSlug?.slug
];

const dynamicPageWithStaticContent = [
  "/data-science-specialization-course",
  "/data-science-course-training-bangalore",
  "/data-science-course-in-gurgaon",
  "/data-science-course-in-delhi",
  "/business-analytics-data-science-course",
  "/business-analytics-course-in-bangalore",
  "/business-analyst-course-in-delhi",
  "/machine-learning-course-certification-training"
];

export const EMICourse = "INR 6,387/- EMI*";
export const EMICoursePrice8575 = "INR 8,575/- EMI*";
export const EMICoursePriceDataScience8206 = "INR 8,260/- EMI*";
export const EMICoursePriceBusinessAnalytics8206 = "INR 7,473/- EMI*";
export const EMICoursePrice12764 = "INR 12,764/- EMI*";
export const EMICoursePrice9361 = "INR 9,361/- EMI*";
export const EMICoursePrice10581 = "INR 10,581/- EMI*"; // use this const in LG Page & in "staticContentAllPages" So Check Before Change.
export const EMITextCourse = `( WITH <b>PAY AFTER PLACEMENT</b> )`;
export const StaticFeesDetailsText = "(Save up to 40% on fees*)";

export const staticContentAllPages = [
  {
    slug: "/data-science-specialization-course",
    EMICourse: EMICoursePriceDataScience8206,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: "/data-science-course-in-delhi",
    EMICourse: EMICoursePriceDataScience8206,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: DataScienceNoidaCourseSlug.slug,
    EMICourse: EMICoursePriceDataScience8206,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: `/data-science-course-in-gurgaon`,
    EMICourse: EMICoursePriceDataScience8206,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: "/data-science-course-training-bangalore",
    EMICourse: EMICoursePriceDataScience8206,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: "/business-analytics-data-science-course",
    EMICourse: EMICoursePriceBusinessAnalytics8206,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: "/business-analyst-course-in-delhi",
    EMICourse: EMICoursePriceBusinessAnalytics8206,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: "/business-analytics-course-in-bangalore",
    EMICourse: EMICoursePriceBusinessAnalytics8206,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: AIEngineeringCourseSlug.slug,
    EMICourse: EMICoursePrice8575,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: AICourseInDelhiDataSlug.slug,
    EMICourse: EMICoursePrice8575,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: AICourseInBangaloreDataSlug.slug,
    EMICourse: EMICoursePrice8575,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: "/machine-learning-course-certification-training",
    EMICourse: EMICoursePrice8575,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: dataScienceWithAiSpecializationSlug.slug,
    EMICourse: EMICoursePrice12764,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: AIForManagersLeadersSlug?.slug,
    EMICourse: EMITextALForManagersLeaders,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: GenerativeAICourseSlug?.slug,
    EMICourse: EMITextGenerativeAICourse,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: "/data-analyst-training-course-in-delhi",
    EMICourse: EMICoursePrice10581,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: "/data-analyst-training-course-in-noida",
    EMICourse: EMICoursePrice10581,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: "/data-analyst-training-course-in-gurgaon",
    EMICourse: EMICoursePrice10581,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  },
  {
    slug: "/data-analytics-training-course-in-bangalore",
    EMICourse: EMICoursePrice10581,
    EMITextCourse: EMITextCourse,
    feesDetailsText: StaticFeesDetailsText
  }
];

export const StaticDataShowFeesDetailsDynamicPages = [
  "/deep-learning-with-python",
  "/business-analyst-training-course-online",
  "/data-science-r-analytics-training",
  "/advanced-excel-training-certification-course-online",
  "/big-data-analytics-hadoop-spark-training-course-online"
];

// MonthsEMIAvailableTextNotShow If true then text not show:  "12 months EMI available at 0% interest (though education financing partners)."
const MonthsEMIAvailableTextNotShowDescriptionLits = [
  {
    title: "Fees payable in up to 3 installment​s"
  },
  {
    title: `Cost-effective courses with high ROI, making it worth every penny you invest.`
  }
];

// never Export
const StaticDataDescriptionLits = [
  {
    title: "Fees payable in up to 3 installment​s"
  },
  {
    title:
      "12 months EMI available at 0% interest (though education financing partners)."
  },
  {
    title: `Cost-effective courses with high ROI, making it worth every penny you invest.`
  }
];
// never Export
const ClassroomAndBootCamp = {
  path: socialImage,
  name: "Classroom & Bootcamp",
  description:
    "Skill mastery with immersive, hands-on learning guided by mentors. Opt for either our intensive weekday bootcamps or flexible weekend classes to suit your schedule. Experience firsthand the power of in-person mentorship, available  in weekday classroom bootcamps, ensuring a rich, experiential learning environment."
};
// never Export
const FullyInteractiveLiveOnline = {
  path: liveImage,
  name: "Fully Interactive Live Online",
  description:
    "Blend the dynamic experience of traditional classroom with engaging, real-time interactive sessions, carefully tailored to meet the demand of busy schedules. This innovative approach ensures effective learning, fostering a deeper understanding and retention of knowledge."
};
// never Export
const BlendedELearning = {
  path: playImage,
  name: "Blended eLearning",
  description:
    "Fuse the rich atmosphere of classroom instruction with the flexibility and accessibility of eLearning modules, meticulously integrated to accommodate learning preferences. This unique blend ensures an optimal learning experience, empowering participants to delve into subjects deeply."
};

export const StaticDataLearningModes = [
  {
    path: ClassroomAndBootCamp.path,
    name: ClassroomAndBootCamp.name,
    description: ClassroomAndBootCamp.description,
    descriptionLits: StaticDataDescriptionLits
  },
  {
    path: FullyInteractiveLiveOnline.path,
    name: FullyInteractiveLiveOnline.name,
    description: FullyInteractiveLiveOnline.description,
    descriptionLits: StaticDataDescriptionLits
  },
  {
    path: BlendedELearning.path,
    name: BlendedELearning.name,
    description: BlendedELearning.description,
    descriptionLits: StaticDataDescriptionLits
  }
];

export const MonthsEMIAvailableTextNotShowStaticDataLearningModes = [
  {
    path: ClassroomAndBootCamp.path,
    name: ClassroomAndBootCamp.name,
    description: ClassroomAndBootCamp.description,
    descriptionLits: MonthsEMIAvailableTextNotShowDescriptionLits
  },
  {
    path: FullyInteractiveLiveOnline.path,
    name: FullyInteractiveLiveOnline.name,
    description: FullyInteractiveLiveOnline.description,
    descriptionLits: MonthsEMIAvailableTextNotShowDescriptionLits
  },
  {
    path: BlendedELearning.path,
    name: BlendedELearning.name,
    description: BlendedELearning.description,
    descriptionLits: MonthsEMIAvailableTextNotShowDescriptionLits
  }
];

export const careerSupportStaticPlusDynamic = [
  {
    courseCareerSupportID: 583,
    courseID: 66,
    careerSupportID: 587,
    sortOrder: 1,
    createdAt: "2020-09-28T09:27:47.000Z",
    updatedAt: "2020-09-30T16:30:02.000Z",
    CareerSupport: {
      careerSupportID: 587,
      title: "No Upfront Fees",
      image: "image/course/Analytixlabs-icons-svg-36.svg",
      description: `We believe in shared success. That's why we offer a unique model which comes with zero upfront costs. Instead, you'll only pay for the Placement Readiness Program (PRP) if you secure a job with a minimum salary of INR 7 Lakhs per annum within six months of completing PRP.`,
      isActive: true,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2024-05-01T07:46:38.000Z"
    }
  },
  {
    courseCareerSupportID: 585,
    courseID: 66,
    careerSupportID: 589,
    sortOrder: 2,
    createdAt: "2020-09-28T09:27:47.000Z",
    updatedAt: "2020-09-30T16:30:02.000Z",
    CareerSupport: {
      careerSupportID: 589,
      title: "Placement Readiness Program",
      image: "image/course/Analytixlabs-icons-svg-34.svg",
      description:
        "An optional 2-month, industry-focused module designed to enhance technical and soft skills. It includes coaching, key topic recaps for interviews, practice tests, case studies, and simulated recruitment drives. The program also features mock interviews with an industry panel, providing one-to-one feedback and guidance.",
      isActive: true,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2024-05-01T07:46:38.000Z"
    }
  },
  {
    courseCareerSupportID: 586,
    courseID: 66,
    careerSupportID: 590,
    sortOrder: 3,
    createdAt: "2020-09-28T09:27:47.000Z",
    updatedAt: "2020-09-30T16:30:02.000Z",
    CareerSupport: {
      careerSupportID: 590,
      title: "Flexible Opt-in",
      image: "image/course/Analytixlabs-icons-svg-33.svg",
      description:
        "After certification you can take upto 30 days to decide and opt in for the Placement Readiness Program. This option is ideal for those who are looking for upskilling or a coveted industry-recognized certificate, feel confident in their acquired skills, and wish to pursue job applications independently. These students will still be eligible for basic placement guidance.",
      isActive: true,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2024-05-01T07:46:38.000Z"
    }
  },
  {
    courseCareerSupportID: 584,
    courseID: 66,
    careerSupportID: 588,
    sortOrder: 4,
    createdAt: "2020-09-28T09:27:47.000Z",
    updatedAt: "2020-09-30T16:30:02.000Z",
    CareerSupport: {
      careerSupportID: 588,
      title: "Continuous Support",
      image: "image/course/Analytixlabs-icons-svg-35.svg",
      description:
        "After you successfully complete PRP, we will continue offering ongoing support as long as you need it. Many of our students benefit from numerous interview calls and rewarding career prospects attributed to the skills acquired during the course.",
      isActive: true,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2024-05-01T07:46:38.000Z"
    }
  }
];

/*
  CourseInfo Course Page : 
  CourseInfo Course Page checkMatchPage function must Have to added Three places use checkMatchPage function if Not Show Section have to match then add "!"(NOT) for full fill situation.
*/

// Added New Section or Show Section Wise Array

// #lession14
export const showCareerPath = [
  AIForManagersLeadersSlug?.slug,
  GenerativeAICourseSlug?.slug
];

// #lession15
export const showLearningBenefits = [AIForManagersLeadersSlug?.slug];

// Not Show Section Wise Array

// #projects
export const notShowCapstoneProject = [
  AIForManagersLeadersSlug?.slug,
  GenerativeAICourseSlug?.slug
];

// #format :: Delivery Format
export const notShowDeliveryFormat = [AIForManagersLeadersSlug?.slug];

export const checkMatchPage = (array, url) => {
  return array.includes(url);
};
/*
  CourseInfo Course Page
*/

/*
  "/telus"  Page : Added logic in LG Page Trello: https://trello.com/c/9noL7XfU
*/
export const telusPageSlug = ["/business-analytics-data-science-course/telus"];

export const businessAnalyticsDataScienceCourse =
  "/business-analytics-data-science-course";

export {
  staticCoursePageSlugArray,
  AIEngineeringCourseSlug,
  DataScienceNoidaCourseSlug,
  dataScienceWithAiSpecializationSlug,
  withStaticCoursePageSlugArray,
  // onlyStaticCoursePageSlugArray,
  staticCourseFormDownloadBrochure,
  StaticPGCityPage,
  AICourseInBangaloreDataSlug,
  ContactUsModalFormStaticDataShowArray,
  dynamicPageWithStaticContent,
  StaticPGCityPageCTAContactUsModal,
  AICourseInDelhiDataSlug,
  AIForManagersLeadersSlug,
  GenerativeAICourseSlug
};
